<template>
  <v-dialog v-model="dialog" max-width="580" persistent scrollable>
    <v-card>
      <v-card-title>
        {{ $t("elearning.copy_for_grade") }}
        <v-spacer></v-spacer>
        <v-icon @click="closeModalHandler">mdi-close</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-6">
        <v-form ref="form" v-model="valid">
          <FilterSchoolYear
            :triggerChange="dialog"
            :itemDisable="itemDisable"
            :dataFilter="dataFilter"
            isVertical
            widthHorizontal
            notMultiple
            @handler="getFilter"
          />

          <v-row no-gutters class="mt-3">
            <v-alert
              v-if="searchElearningFlag"
              color="warning"
              class="caption white--text mb-0"
              dense
            >
              {{ $t("elearning.subject_not_found") }}
            </v-alert>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="!filter.class"
              color="primary"
              :loading="searchElearningLoading"
              depressed
              @click="searchElearning()"
              >{{ $t("app.search") }}</v-btn
            >
          </v-row>
        </v-form>

        <v-divider class="my-4"></v-divider>

        <v-sheet v-if="elearningList.length > 0" class="mt-4">
          <v-card>
            <v-data-table
              v-model="selectedElearning"
              :headers="headersElearning"
              :items="elearningList"
              item-key="id"
              show-select
            >
              <template v-slot:item.lesson_name="{ item }">
                {{ item.lesson_name }}
                <span v-if="item.sub">{{ item.sub }}</span>
              </template>
              <template v-slot:item.basic_competencies="{ item, value }">
                <v-row v-if="value.length > 1" no-gutters align="start">
                  <span v-if="!item.viewMore">
                    <span>{{ value[0].kd_chapter }}</span>
                  </span>
                  <span v-else>
                    <div v-for="ht in value" :key="ht.id" class="my-2">
                      {{ ht.kd_chapter }}
                    </div>
                  </span>

                  <v-spacer></v-spacer>

                  <v-btn
                    small
                    icon
                    @click="item.viewMore = !item.viewMore"
                    :class="item.viewMore ? 'mt-2' : ''"
                  >
                    <v-icon v-if="item.viewMore">mdi-chevron-up</v-icon>
                    <v-icon v-else>mdi-chevron-down</v-icon>
                  </v-btn>
                </v-row>
                <v-row v-if="value.length == 1" no-gutters>{{
                  value[0].kd_chapter
                }}</v-row>
              </template>
            </v-data-table>
          </v-card>
        </v-sheet>
      </v-card-text>
      <v-card-actions class="mt-2">
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!valid || selectedElearning.length == 0"
          :loading="loadingSubmit"
          color="primary"
          depressed
          @click="submit"
          class="mt-3"
        >
          {{ $t("app.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  list_elearning_by_class,
  copy_to_lesson
} from "@/api/admin/academic/eLearning";
import FilterSchoolYear from "@/views/admin/components/filterSchooYear";

export default {
  components: { FilterSchoolYear },
  props: {
    dialog: Boolean,
    lessonId: [String, Number],
    lesson: String,
    closeModal: Function,
    getData: Function
  },
  data() {
    return {
      valid: false,
      elearningList: [],
      selectedElearning: [],
      classList: [],
      searchElearningLoading: false,
      loadingClass: false,
      searchElearningFlag: false,
      loadingSubmit: false,
      headersElearning: [
        {
          text: this.$i18n.t("elearning.table.subject"),
          align: "left",
          value: "lesson_name"
        },
        {
          text: this.$i18n.t("elearning.table.title"),
          align: "left",
          value: "title"
        },
        {
          text: this.$i18n.t("elearning.table.kd"),
          sortable: false,
          value: "basic_competencies"
        }
      ],
      filter: {
        school_year: "",
        grade: "",
        class: ""
      },
      filterList: {
        schoolYear: [],
        grade: [],
        class: []
      },
      form: {
        lesson: this.lessonId,
        school_year: "",
        grade: "",
        class: ""
      },
      loadingFilter: {
        schoolYear: false,
        grade: false,
        class: false
      },
      itemDisable: {},
      dataFilter: {}
    };
  },
  watch: {
    dialog() {
      this.$nextTick(() => {
        const p = this.$route.params;
        const data = {
          year: [Number(p.year)],
          grade: Number(p.grade),
          class: Number(p.class)
        };
        this.itemDisable = data;
        this.dataFilter.year = data.year;
        this.dataFilter.grade = data.grade;
        this.dataFilter.class = data.class;
      });
    }
  },
  methods: {
    getFilter(res) {
      switch (res.type) {
        case "school_year":
          this.form.school_year = res.data.id;
          this.filter.school_year = res.data.id;
          break;
        case "select_grade":
          this.form.grade = res.data;
          this.filter.grade = res.data;
          break;
        default:
          this.form.class = res.data;
          this.filter.class = res.data;
          break;
      }
    },
    closeModalHandler() {
      this.$refs.form.reset();
      this.searchElearningFlag = false;
      this.elearningList = [];
      this.closeModal();
    },
    searchElearning() {
      this.searchElearningLoading = true;
      this.searchElearningFlag = false;
      list_elearning_by_class({
        school_year: this.filter.school_year,
        classroom: this.filter.class
      })
        .then(res => {
          if (res.data.code) {
            const d = res.data.data;
            const newData = [];
            d.map(r => newData.push({ ...r, viewMore: false }));
            this.elearningList = newData;

            if (res.data.data.length == 0) this.searchElearningFlag = true;
          } else {
            this.$store.commit("CALL_SNACKBAR", {
              msg: res.data.message,
              color: "error"
            });
          }
          this.searchElearningLoading = false;
        })
        .catch(() => {
          this.searchElearningLoading = false;
        });
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.loadingSubmit = true;
        if (this.selectedElearning.length > 0) {
          const classAndLesson = [];
          this.selectedElearning.map(r => {
            classAndLesson.push({
              class: r.classroom,
              lesson: r.lesson
            });
          });

          const data = {
            elearnings: this.selectedElearning.map(r => r.id),
            class_to: this.$route.params.class,
            lesson_to: this.$route.params.id,
            type: "ELEARNING"
          };

          copy_to_lesson(data)
            .then(res => {
              if (res.data.code) {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: this.$i18n.t("app.success_copy"),
                  color: "success"
                });
                this.getData();
                this.selectedElearning = [];
                this.closeModalHandler();
              } else {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: res.data.message,
                  color: "error"
                });
              }
              this.loadingSubmit = false;
            })
            .catch(() => {
              this.loadingSubmit = true;
            });
        }
      }
    }
  }
};
</script>
