<template>
  <v-sheet class="pa-4" :class="!darkmode && 'grey lighten-5'">
    <v-row no-gutters>
      <v-btn
        class="font-weight-bold mb-3"
        depressed
        @click="
          $router.push({
            path: '/academic/e-learning',
            query: {
              year: param.year,
              grade: param.grade,
              class: param.class
            }
          })
        "
      >
        <v-icon class="mr-2">
          mdi-arrow-left
        </v-icon>
        {{ $t("app.back") }}
      </v-btn>
    </v-row>

    <v-row no-gutters>
      <v-card
        :class="darkmode ? 'black' : 'white'"
        class="rounded-lg pa-4"
        flat
        width="100%"
      >
        <v-row no-gutters justify="start">
          <v-col cols="6" sm="4" lg="2" class="divider pl-2">
            <div class="font-weight-light">{{ $t("subjects.subjects") }}</div>
            <div class="font-weight-bold">
              {{ lesson.name }} <span v-if="lesson.sub">{{ lesson.sub }}</span>
            </div>
          </v-col>
          <v-col cols="6" sm="4" lg="1" class="divider pl-2">
            <div class="font-weight-light">{{ $t("app.grade") }}</div>
            <div class="font-weight-bold">{{ lesson.grade }}</div>
          </v-col>
          <v-col cols="6" sm="4" lg="2" class="divider pl-2">
            <div class="font-weight-light">{{ $t("app.class") }}</div>
            <div class="font-weight-bold">{{ lesson.class }}</div>
          </v-col>
          <v-col cols="6" sm="4" lg="2" class="divider pl-2">
            <div class="font-weight-light">
              {{ $t("elearning.table.total") }} E-Learning
            </div>
            <div class="font-weight-bold">{{ lesson.total }}</div>
          </v-col>
          <v-col cols="6" sm="4" lg="2" class="divider pl-2">
            <div class="font-weight-light">{{ $t("app.school_year") }}</div>
            <div class="font-weight-bold">{{ lesson.school_year }}</div>
          </v-col>
          <v-col cols="6" sm="4" lg="1" class="pl-2">
            <div class="font-weight-light">Semester</div>
            <div class="font-weight-bold">{{ lesson.semester }}</div>
          </v-col>
          <v-col
            v-if="checkPrivilages('ELR03') && isTeacherCreator()"
            cols="12"
            sm="12"
            :class="$vuetify.breakpoint.lgAndDown && 'mt-4'"
          >
            <v-row no-gutters align="center" class="mb-2">
              <v-btn
                color="primary"
                depressed
                small
                class="mr-1"
                @click="modalAddStatus = true"
              >
                E-Learning
                <v-icon small class="pl-1">mdi-plus-circle-outline</v-icon>
              </v-btn>
              <v-btn
                color="primary"
                depressed
                small
                @click="modalCopyStatus = true"
              >
                {{ $t("app.copy") }} {{ $t("app.from") }}
                <v-icon small class="pl-1">mdi-content-copy</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-checkbox
                v-model="queryData.my_elearning"
                hide-details
                :class="{ 'mt-n3': !$vuetify.breakpoint.xsOnly }"
                :label="$t('elearning.my_elearning')"
                @change="getData()"
              ></v-checkbox>
            </v-row>
            <v-row no-gutters justify="end">
              <div
                v-if="selectedElearning.length > 0"
                :class="{ 'pb-3': $vuetify.breakpoint.xsOnly }"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      depressed
                      color="primary"
                      class="mr-3"
                      small
                      @click="setAction(null, 'published')"
                    >
                      <v-icon>mdi-check-circle-outline</v-icon>
                    </v-btn>
                  </template>
                  {{ $t("app.publish") }}
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      depressed
                      color="primary"
                      class="mr-3"
                      small
                      @click="setAction(null, 'unpublished')"
                    >
                      <v-icon>mdi-close-circle-outline</v-icon>
                    </v-btn>
                  </template>
                  {{ $t("app.unpublish") }}
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      depressed
                      color="primary"
                      class="mr-3"
                      small
                      @click="setAction(null, 'delete')"
                    >
                      <v-icon>mdi-delete-forever</v-icon>
                    </v-btn>
                  </template>
                  {{ $t("app.delete") }}
                </v-tooltip>
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-card>

      <AddElearning
        :dialog="modalAddStatus"
        :closeModal="closeModalForm"
        :lessonId="lessonId"
        :editData="editData"
        :basicCompetenciesList="basicCompetenciesList"
      />

      <CopyElearning
        :dialog="modalCopyStatus"
        :closeModal="() => (modalCopyStatus = false)"
        :lessonId="$route.params.id"
        :lesson="lesson.name"
        :getData="
          () => {
            this.getData();
            this.getDetailElearning();
          }
        "
      />

      <v-card
        :class="darkmode ? 'black' : 'white'"
        class="rounded-lg pa-4 mt-4"
        flat
        width="100%"
      >
        <v-data-table
          v-model="selectedElearning"
          :headers="
            $store.getters.g_role_type === 'STUDENT'
              ? tableHeadersStudent
              : tableHeaders
          "
          :items="dataTable"
          :page.sync="queryData.page"
          :items-per-page="queryData.limit"
          :loading="loading"
          :show-select="
            $store.getters.g_role_type != 'STUDENT' && isTeacherCreator()
          "
          hide-default-footer
          class="elevation-0"
        >
          <template v-slot:item.created_at="{ item }">
            {{ item.created_at | dateFormat }}
          </template>
          <template v-slot:item.basic_competencies="{ item, value }">
            <v-row v-if="value.length > 1" no-gutters align="start">
              <span v-if="!item.viewMore">
                <span>{{ value[0].kd_chapter }}</span>
              </span>
              <span v-else>
                <div v-for="ht in value" :key="ht.id" class="my-2">
                  {{ ht.kd_chapter }}
                </div>
              </span>

              <v-spacer></v-spacer>

              <v-btn
                small
                icon
                @click="item.viewMore = !item.viewMore"
                :class="item.viewMore ? 'mt-2' : ''"
              >
                <v-icon v-if="item.viewMore">mdi-chevron-up</v-icon>
                <v-icon v-else>mdi-chevron-down</v-icon>
              </v-btn>
            </v-row>
            <v-row v-if="value.length == 1" no-gutters>{{
              value[0].kd_chapter
            }}</v-row>
          </template>
          <template #item.status="{item}">
            <span>{{
              item.status == "UNPUBLISHED"
                ? $t("elearning.table.unpublish")
                : $t("elearning.table.published")
            }}</span>
          </template>
          <template v-slot:item.action="{ item }">
            <v-menu
              v-if="checkPrivilages('ELR04') && isTeacherCreator()"
              transition="slide-x-transition"
              bottom
              right
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  :color="selectedElearning.length == 0 ? 'primary' : ''"
                  >mdi-dots-horizontal</v-icon
                >
              </template>
              <v-list v-if="selectedElearning.length == 0">
                <v-list-item @click="setAction(item, 'view')">
                  <v-icon color="primary" class="mr-2">mdi-eye</v-icon>
                  {{ $t("app.view") }}
                </v-list-item>
                <v-list-item @click="setEdit(item)">
                  <v-icon color="primary" class="mr-2"
                    >mdi-pencil-box-outline</v-icon
                  >
                  {{ $t("app.edit") }}
                </v-list-item>
                <v-list-item @click="setAction(item, 'published')">
                  <v-icon color="primary" class="mr-2"
                    >mdi-check-circle-outline</v-icon
                  >
                  {{ $t("app.publish") }}
                </v-list-item>
                <v-list-item @click="setAction(item, 'unpublished')">
                  <v-icon color="primary" class="mr-2"
                    >mdi-close-circle-outline</v-icon
                  >
                  {{ $t("elearning.cancel_publish") }}
                </v-list-item>
                <v-list-item @click="setAction(item, 'delete')">
                  <v-icon color="primary" class="mr-2"
                    >mdi-delete-forever</v-icon
                  >
                  {{ $t("app.delete") }}
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn
              v-else
              icon
              small
              dark
              class="primary"
              :disabled="loading"
              @click="setAction(item, 'view')"
            >
              <v-icon small>mdi-eye</v-icon>
            </v-btn>
          </template>
        </v-data-table>

        <Pagination
          :length="length"
          :total="total"
          :current-page="queryData.page"
          :limit="queryData.limit"
          :handler="paginateChange"
        />
      </v-card>
    </v-row>

    <ModalConfirm
      :title="confirmTitle"
      :close="() => (this.confirmModal = false)"
      :loading="confirmLoading"
      :isOpen="confirmModal"
      :save="confirmAction"
    >
      <div v-if="confirmType == 'published'">
        <div class="mb-2">
          <span v-if="selectedElearningSingle">
            {{ $t("elearning.confirm.publish_text_1") }}
            <strong>{{ selectedElearningSingle.title }}</strong
            >?
          </span>
          <span v-else>
            {{ $t("elearning.confirm.publish_text_1_bulk") }}?
          </span>
        </div>
        <div class="mb-2">{{ $t("elearning.confirm.publish_text_2") }}</div>
        <div>{{ $t("elearning.confirm.publish_text_3") }}</div>
      </div>
      <div v-else-if="confirmType == 'unpublished'">
        <div class="mb-2">
          <span v-if="selectedElearningSingle">
            {{ $t("elearning.confirm.unpublish_text_1") }}
            <strong>{{ selectedElearningSingle.title }}</strong
            >?
          </span>
          <span v-else>
            {{ $t("elearning.confirm.unpublish_text_1_bulk") }}?
          </span>
        </div>
        <div class="mb-2">{{ $t("elearning.confirm.unpublish_text_2") }}</div>
      </div>
      <div v-else>
        {{ confirmText }}
      </div>
    </ModalConfirm>
  </v-sheet>
</template>

<script>
import i18n from "@/i18n";
import { checkPrivilages } from "@/utils/privilages";
import moment from "moment/moment";
moment.locale("id");

import {
  get_detail_lesson,
  get_list_elearning,
  elearning_delete,
  elearning_update_status
} from "@/api/admin/academic/eLearning";

import AddElearning from "./components/AddElearning";
import CopyElearning from "./components/CopyElearning";
import ModalConfirm from "@/components/ModalConfirm";
import Pagination from "@/components/Pagination";

import { getTeacherByLesson } from "@/api/admin/admin";

export default {
  components: {
    AddElearning,
    CopyElearning,
    ModalConfirm,
    Pagination
  },
  filters: {
    dateFormat: value => {
      if (!value) return "";
      return moment(value).format("DD MMM YYYY");
    }
  },
  data() {
    return {
      param: this.$route.params,
      modalAddStatus: false,
      modalCopyStatus: false,
      lessonId: this.$route.params.id,
      classId: this.$route.params.class,
      selectedElearningSingle: null,
      selectedElearning: [],
      lesson: {
        name: "",
        sub: "",
        grade: "",
        class: "",
        total: "",
        school_year: "",
        semester: ""
      },
      queryData: {
        lesson: this.$route.params.id,
        classroom: this.$route.params.class,
        my_elearning:
          this.$store.getters.g_role_type === "ADMIN" ? false : true,
        page: 1,
        limit: 10,
        filter: {
          status: [],
          title: "",
          basic_competencies: ""
        },
        order: {}
      },
      tableHeadersStudent: [
        {
          text: i18n.t("elearning.table.created_at"),
          align: "left",
          value: "created_at",
          sortable: false
        },
        {
          text: i18n.t("elearning.table.title"),
          value: "title"
        },
        {
          text: i18n.t("elearning.table.kd"),
          value: "basic_competencies"
        },
        {
          text: i18n.t("elearning.table.total"),
          sortable: false,
          value: "published_elearning_content"
        },
        {
          text: i18n.t("core_competencies.table.action"),
          sortable: false,
          value: "action",
          width: 75
        }
      ],
      tableHeaders: [
        {
          text: i18n.t("elearning.table.created_at"),
          align: "left",
          value: "created_at",
          sortable: false
        },
        {
          text: i18n.t("elearning.table.title"),
          value: "title"
        },
        {
          text: i18n.t("elearning.table.kd"),
          value: "basic_competencies"
        },
        {
          text: i18n.t("elearning.table.published"),
          sortable: false,
          value: "published_elearning_content"
        },
        {
          text: i18n.t("elearning.table.unpublish"),
          sortable: false,
          value: "unpublished_elearning_content"
        },
        {
          text: i18n.t("elearning.table.status"),
          sortable: false,
          value: "status"
        },
        {
          text: i18n.t("core_competencies.table.action"),
          sortable: false,
          value: "action",
          width: 75
        }
      ],
      loading: false,
      total: 0,
      length: 0,
      dataTable: [],
      confirmTitle: "",
      confirmModal: false,
      confirmLoading: false,
      confirmType: "",
      confirmId: null,
      confirmText: "",
      basicCompetenciesList: [],
      editData: {},
      teacherCreator: null
    };
  },
  computed: {
    darkmode() {
      return this.$store.getters.g_darkmode;
    },
    g_user() {
      return this.$store.getters.g_user;
    }
  },
  async created() {
    await this.getTeacherByLessonHandler();
    await this.getDetailElearning();
    this.getData();
  },
  methods: {
    getTeacherByLessonHandler() {
      const p = this.$route.params;
      const query = {
        lesson: p.id,
        classroom: p.class
      };
      getTeacherByLesson(query).then(res => {
        if (res.data.code) {
          this.teacherCreator = res.data.data;
        }
      });
    },
    isTeacherCreator() {
      if (this.teacherCreator && this.teacherCreator.length > 0) {
        const teacherId = this.teacherCreator.map(r => r.id_person);
        if (this.g_user.role_type == "ADMIN") {
          return true;
        } else {
          return teacherId.indexOf(this.g_user.person) >= 0;
        }
      } else {
        return false;
      }
    },
    setEdit(param) {
      this.editData = {};
      this.$nextTick(() => {
        this.editData = param;
        this.modalAddStatus = true;
      });
    },
    closeModalForm(param) {
      if (param) {
        this.getDetailElearning();
        this.getData();
        this.editData = {};
      }
      this.$nextTick(() => (this.modalAddStatus = false));
    },
    checkPrivilages: name => checkPrivilages(name),
    confirmAction() {
      const status = this.confirmType.toUpperCase();
      this.confirmLoading = true;
      switch (this.confirmType) {
        case "delete":
          elearning_delete({ elearnings: this.confirmId })
            .then(async res => {
              if (res.data.code) {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: this.$i18n.t("app.success_delete"),
                  color: "success"
                });
                await this.getDetailElearning();
                await this.getData();
                this.selectedElearning = [];
                this.confirmModal = false;
              } else {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: res.data.message,
                  color: "error"
                });
              }
              this.confirmLoading = false;
            })
            .catch(() => {
              this.confirmLoading = false;
            });
          break;
        default:
          elearning_update_status({
            elearnings: this.confirmId,
            status: status
          })
            .then(async res => {
              this.$store.commit("CALL_SNACKBAR", {
                msg: res.data.message,
                color: res.data.code ? "success" : "error"
              });

              if (res.data.code) {
                await this.getData();
                this.confirmModal = false;
                this.selectedElearning = [];
              }
              this.confirmLoading = false;
            })
            .catch(() => {
              this.confirmLoading = false;
            });
      }
    },
    setAction(item, type) {
      this.confirmType = type;
      if (item) this.confirmId = [item.id];
      else this.confirmId = this.selectedElearning.map(r => r.id);
      this.selectedElearningSingle = item;

      const p = this.param;
      switch (type) {
        case "published":
          this.confirmTitle = i18n.t("elearning.confirm.publish");
          break;
        case "unpublished":
          this.confirmTitle = i18n.t("elearning.confirm.unpublish");
          break;
        case "delete":
          this.confirmTitle = i18n.t("elearning.confirm.delete");
          this.confirmText = i18n.t("elearning.confirm.delete_text");
          break;
        default:
          this.$router.push(
            `/academic/e-learning/${p.year}/${p.grade}/${p.class}/${this.lessonId}/${item.id}`
          );
          break;
      }
      if (type !== "view") this.confirmModal = true;
    },
    getDetailElearning() {
      get_detail_lesson({ lesson: this.lessonId, class: this.classId }).then(
        res => {
          const d = res.data.data;
          this.lesson = {
            name: d.lesson,
            sub: d.sub,
            grade: d.grade,
            class: d.name,
            total: d.total_elearning,
            school_year: d.start_year + " / " + d.end_year,
            semester: d.semester
          };
          this.basicCompetenciesList = d.basic_competencies;
        }
      );
    },
    getData() {
      this.loading = true;
      get_list_elearning(this.queryData)
        .then(res => {
          if (res.data.code) {
            const d = res.data.data;
            const newData = [];
            d.data.map(r => newData.push({ ...r, viewMore: false }));
            this.dataTable = newData;
            this.total = d.total;
            this.length = d.last_page;
          } else {
            this.$store.commit("CALL_SNACKBAR", {
              msg: res.data.message,
              color: "error"
            });
          }
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    paginateChange(page, limit) {
      this.queryData.page = page;
      this.queryData.limit = limit;
      this.getData();
    }
  }
};
</script>

<style lang="scss">
.divider {
  border-right: 1px solid #e2e2e2;
}
</style>
