<template>
  <v-dialog v-model="dialog" max-width="600" persistent>
    <v-card>
      <v-card-title>
        {{
          editData.id
            ? $t("elearning.edit_elearning")
            : $t("elearning.add_elearning")
        }}
        <v-spacer></v-spacer>
        <v-icon @click="closeModal()">mdi-close</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text class="mt-6">
        <v-form ref="form" v-model="valid">
          <v-sheet>
            <v-text-field
              v-model="form.title"
              :label="$t('elearning.title')"
              :rules="[v => !!v || $t('app.required')]"
              dense
              outlined
              required
            />
            <v-divider class="mb-6" />
            <v-autocomplete
              v-model="form.basic_competencies"
              :label="$t('elearning.basic_competencies')"
              :rules="[v => v.length > 0 || $t('app.required')]"
              :items="basicCompetenciesList"
              item-value="id"
              :item-text="textBasicCompetence"
              :hide-details="valid"
              outlined
              dense
              multiple
              required
            >
            </v-autocomplete>
          </v-sheet>
          <v-row no-gutters>
            <v-spacer />
            <v-btn
              color="primary"
              depressed
              :loading="loadingSubmit"
              @click="submit"
              class="mt-3"
            >
              {{ $t("app.save") }}
            </v-btn>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  create_elearning,
  elearning_edit
} from "@/api/admin/academic/eLearning";

export default {
  props: {
    dialog: Boolean,
    closeModal: Function,
    basicCompetenciesList: Array,
    editData: Object
  },
  data() {
    return {
      valid: false,
      loadingSubmit: false,
      form: {
        lesson: this.$route.params.id,
        title: "",
        classroom: this.$route.params.class,
        basic_competencies: [],
        status: "PUBLISHED"
      }
    };
  },
  watch: {
    dialog() {
      if (this.editData) {
        let getBasicCompetencies = [];
        if (
          this.editData.basic_competencies &&
          this.editData.basic_competencies.length > 0
        ) {
          getBasicCompetencies = this.editData.basic_competencies.map(
            r => r.basic_competencies
          );

          this.form = {
            elearning: this.editData.id,
            lesson: this.$route.params.id,
            title: this.editData.title,
            classroom: this.$route.params.class,
            basic_competencies: getBasicCompetencies
          };
        }
      }
    }
  },
  methods: {
    textBasicCompetence(e) {
      return e.core_competencies + " - " + e.kd_chapter;
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.loadingSubmit = true;
        if (this.editData.id) {
          elearning_edit(this.form)
            .then(res => {
              if (res.data.code) {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: this.$i18n.t("app.success_save"),
                  color: "success"
                });
                this.closeModal("save");
              } else {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: res.data.message,
                  color: "error"
                });
              }
              this.loadingSubmit = false;
            })
            .catch(() => {
              this.loadingSubmit = false;
            });
        } else {
          create_elearning(this.form)
            .then(res => {
              if (res.data.code) {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: this.$i18n.t("app.success_save"),
                  color: "success"
                });
                this.closeModal("save");
              } else {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: res.data.message,
                  color: "error"
                });
              }
              this.loadingSubmit = false;
            })
            .catch(() => {
              this.loadingSubmit = false;
            });
        }
      }
    }
  }
};
</script>
